import React, {CSSProperties, FC, ReactNode} from 'react';
import {Helmet} from "react-helmet";

interface MainProps {
    title: string;
    children: ReactNode
    style?: CSSProperties
}

const Main: FC<MainProps> = ({title, children, style}) => {
    return <main style={style}>
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <title>Tijo Salverda • {title}</title>
        </Helmet>
        {children}
    </main>

}

export default Main;